import { images } from "../constants";

const cookingOil = [
    {
        name: "Naz Cooking Oil",
        headline: "Naz Cooking Oil",
        tag: "ہمیں ناز ہیکہ ہم ہیں پاکستان",
        desc: "The Vitamins A & D Found In Naz Cooking Oil Improve Your Eye Sight And Further Stronger Your Bones That Helps You Grow In A Balanced Way.<br><br> Naz Cooking Oil Is Safe, Rich In Taste And Very Light Cooking Oil.",
        ctg: "Cooking Oil",
        image: images.NazCookingOil,
        thumbnail: images.nazThumbnail,
        packing: {
            tins: {
                Tin_2_5_ltr: "2.5 Litre",
                Tin_5ltr: "5 Litre",
                Tin_10ltr: "10 Litre",
                Tin_16ltr: "16 Litre",
                Can_10ltr: "10 Litre",
            },
            // cartons: {
            //     Bottle_1ltr: "1 Litre",
            //     Bottle_3ltr: "3 Litre",
            //     Box_5ltr: "5 Litre",
            // },
            bottle: {
                Bottle_1x5ltr: "1x5 Litre",
                Bottle_half_ltr: "1/2 Litre",
                Bottle_1_ltr: "1 Litre",
                Bottle_3_ltr: "3 Litre",
                Bottle_5_ltr: "5 Litre",
            },
            Cartons: {
                Box_1x5ltr: "1x5 Litre",
                Box_half_ltr: "1/2 Litre",
            }
        }
    },
    {
        name: "Pure Cooking Oil",
        headline: "Pure cooking oil, the heart-healthy choice!",
        tag: "باندھے دلوں کے بندھن",
        desc: "Not all oils are created equal. Pure cooking oil is a perfectly balanced blend of soya, canola and sunflower oils, designed to give you pure nutrition without the risk of unhealthy fats.<br><br>Heart friendly formula, zero cholesterol & trans-fat, making it ideal for a healthy heart. Immune-boosting goodness, rich in vitamin A & D3 to support immunity, stronger bones and better eyesight. Light & greasy, less oil absorption means crispy, delicious meals without excess fat. Scientifically baked, research shows that high quality oils can reduce inflammation, lower disease risks, and support overall well-being. 100% pure & chemical-free, no additives, no harmful chemicals, just the natural goodness your body needs.<br><br>Upgrade your kitchen with Pure cooking oil, where purity meets nutrition.<br><br>Cook smart, live healthy and enjoy every bite!",
        ctg: "Cooking Oil",
        image: images.PureCookingOil,
        thumbnail: images.pureThumbnail,
        packing: {
            tins: {
                Tin_2_5ltr: "2.5 Litre",
                Tin_5ltr: "5 Litre",
                Tin_10ltr: "10 Litre",
                Tin_16ltr: "16 Litre",
                Tin_16kg: "16 KG",
                Can_10ltr: "10 Litre",
                Can_16ltr: "16 Litre",
                Can_15kg: "15 KG",
            },
            bottle: {
                Bottle_1ltr: "1 Litre",
                Bottle_3ltr: "3 Litre",
                Bottle_5ltr: "5 Litre",
                Bottle_1x6ltr: "1x6 Litre",
            },
            cartons: {
                Carton_1x2ltr: "1x2 Litre",
                Box_1x5_ltr: "1x5 Litre",
            }
        }
    },
    {
        name: "Fry Ola Cooking Oil",
        headline: "Fry Ola Cooking Oil",
        tag: "Feel Delight",
        desc: "Fry-Ola Cooking Oil Is Safe, Rich In Taste And Very Light Cooking Oil. The Vitamins A & D Found In Fry-ola Cooking Oil Improve Your Eye Sight And Further Stronger Your Bones That Helps You Grow In A Balanced Way.",
        ctg: "Cooking Oil",
        image: images.FryOlaCookingOil,
        thumbnail: images.FryOlaCookingOil,
        packing: {
            tins: {
                Bottle_2_5ltr: "2.5 Litre",
                Bottle_5ltr: "5 Litre",
                Box_10ltr: "10 Litre",
                Can_10ltr: "10 Litre",
                Tin_16ltr: "16 Litre",
            },
            bottle: {
                Bottle_1ltr: "1 Litre",
                Bottle_3ltr: "3 Litre",
                Box_5ltr: "5 Litre",
            },
            pouches: {
                Bottle_1x5ltr: "1x5 Litre",
                Bottle_half_ltr: "1/2 Litre",
            }
        }
    },
    {
        name: "Pak Cooking Oil",
        headline: "Pak Cooking Oil",
        tag: "ایک رشتہ اعتماد کا",
        desc: "Pak Cooking Oil Is Safe, Rich In Taste And Very Light Cooking Oil. The Vitamins A & D Found In Naz Cooking Oil Improve Your Eye Sight And Further Stronger Your Bones That Helps You Grow In A Balanced Way.<br><br>Continuous Usage Of Pak Cooking Oil Balances The Level Of Cholesterol And Protects You From Heart Diseases Which Help You To Reduce The Chances Of Getting Paralyzed.",
        ctg: "Cooking Oil",
        image: images.PakCookingOil,
        thumbnail: images.pakThumbnail,
        packing: {
            
            cartons: {
                Carton_1x10L: "1x10L",
                Carton_900ml: "900ml",
            }
        }
    },
    {
        name: "Sun Cooking Oil",
        headline: "Sun Cooking Oil",
        tag: "جب ذائقہ مانگے من سب بولیں سن",
        desc: "Sun Cooking Oil Is Safe, Rich In Taste And Very Light Cooking Oil. The Vitamins A & D Found In Naz Cooking Oil Improve Your Eye Sight And Further Stronger Your Bones That Helps You Grow In A Balanced Way.<br><br>Continuous Usage Of Sun Cooking Oil Balances The Level Of Cholesterol And Protects You From Heart Diseases Which Help You To Reduce The Chances Of Getting Paralyzed.",
        ctg: "Cooking Oil",
        image: images.SunCookingOil,
        thumbnail: images.sunThumbnail,
        packing: {
            tins: {
                Tin_16L: "16L",
                Tin_4_5L: "4.5L",
                Tin_2_25L: "2.25L",
                Can_9L: "9L",

            },
            bottles: {
                Bottle_3L: "3L",
                Bottle_1L: "1L",
            },
            cartons: {
                Carton_900ml: "900ml",
            }
        }
    },
    {
        name: "Naz Cooking Oil",
        headline: "Naz Cooking Oil",
        tag: "ہمیں ناز ہیکہ ہم ہیں پاکستان",
        desc: "The Vitamins A & D Found In Naz Cooking Oil Improve Your Eye Sight And Further Stronger Your Bones That Helps You Grow In A Balanced Way.<br><br> Naz Cooking Oil Is Safe, Rich In Taste And Very Light Cooking Oil.",
        ctg: "Cooking Oil",
        image: images.NazCookingOil,
        thumbnail: images.nazThumbnail,
        packing: {
            tins: {
                Bottle_2_5ltr: "2.5 Litre",
                Bottle_5ltr: "5 Litre",
                Box_10ltr: "10 Litre",
                Can_10ltr: "10 Litre",
                Tin_16ltr: "16 Litre",
            },
            bottle: {
                Bottle_1ltr: "1 Litre",
                Bottle_3ltr: "3 Litre",
                Box_5ltr: "5 Litre",
            },
            pouches: {
                Bottle_1x5ltr: "1x5 Litre",
                Bottle_half_ltr: "1/2 Litre",
            }
        }
    },
];

const banaspati = [
    {
        name: "Naz Banaspati",
        headline: "Naz Banaspati",
        tag: "لذت کا ایک نیا ذائقہ",
        desc: "NAZ Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Banaspati",
        image: images.NazBanaspati,
        thumbnail: images.NazBanaspati,
        packing: {
            tins: {
                Tin_16kg: "16kg",
                Tin_5kg: "5kg",
                Tin_2_5kg: "2.5kg",
            },
            buckets: {
                Bucket_16kg: "16kg",
                Bucket_10kg: "10kg",
                Bucket_5kg: "5kg",
                Bucket_2_5kg: "2.5kg",
            },
            cartons: {
                Carton_100g: "100g",
                Carton_1kg: "1kg",
                Carton_half_kg: "1/2kg",
                Carton_quarter_kg: "1/4kg",
                Box_1x5kg: "1x5kg",
            }        
        }
    },
    {
        name: "Pure Banaspati",
        headline: "Pure Banaspati",
        tag: "پیور ہی جانے دل کی بات",
        desc: "Pure Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Banaspati",
        image: images.PureBanaspati,
        thumbnail: images.PureBanaspati,
        packing : {
            tins: {
                Tin_16kg: "16 kg",
                Tin_10kg: "10 kg",
                Tin_5kg: "5 kg",
                Tin_2_5kg: "2.5 kg",
            },
            buckets: {
                Bucket_16kg: "16 kg",
                Bucket_10kg: "10 kg",
                Bucket_5kg: "5 kg",
                Bucket_2_5kg: "2.5 kg",
            },
            Cartons: {
                Carton_1kg: "1 kg",
                Carton_half_kg: "1/2 kg",
                Carton_quarter_kg: "1/4 kg",
                Box_1x5kg: "1x5 kg",
            }
        }
    },
    {
        name: "Pak Banaspati",
        headline: "Pak Banaspati",
        tag: "نیا پیک زیادہ مزہ",
        desc: "Pak Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Banaspati",
        image: images.PakBanaspati,
        thumbnail: images.PakBanaspati,
        packing: {
            cartons: {
                Carton_1kg: "1kg",
                Carton_half_kg: "1/2kg",
                Carton_quarter_kg: "1/4kg",
            }
        }
    },
    {
        name: "Sun Banaspati",
        headline: "Sun Banaspati",
        tag: "جب زائقہ مانگے من سب بولیں سن",
        desc: "Sun Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Banaspati",
        image: images.SunBanaspati,
        thumbnail: images.SunBanaspati,
        packing: {
            tins: {
                Tin_16kg: "16kg",
                Tin_15_7kg: "15.70kg",
                Tin_4_5kg: "4.5kg",
                Tin_2_25kg: "2.25kg",
            },
            cartons: {
                Carton_900g: "900g",
                Carton_450g: "450g",
                Carton_225g: "225g",
            }
        }
    },
];

const margarine = [
    {
        name: "Kawality Bake",
        headline: "Kawality Bake",
        tag: "---",
        desc: "NAZ Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Margarine",
        image: images.KawalityMargarine,
        thumbnail: images.KawalityMargarine,
        packing: {
            tins: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_16kg: "10kg",
            },
            buckets: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_10kg: "10kg",
                Box_16kg: "16kg",
            },
            pouches: {
                Bottle_1kg: "1kg",
                Bottle_half_ltr: "1/2kg",
                Bottle_quarter_kg: "1/4kg",
                Bottle_1x5kg: "1x5kg",
                Bottle_100g: "100g",
            }
        }
    },
    {
        name: "Royal Mava Margarine",
        headline: "Royal Mava Margarine",
        tag: "---",
        desc: "NAZ Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Margarine",
        image: images.RoyalMavaMargarine,
        thumbnail: images.RoyalMavaMargarine,
        packing: {
            tins: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_16kg: "10kg",
            },
            buckets: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_10kg: "10kg",
                Box_16kg: "16kg",
            },
            pouches: {
                Bottle_1kg: "1kg",
                Bottle_half_ltr: "1/2kg",
                Bottle_quarter_kg: "1/4kg",
                Bottle_1x5kg: "1x5kg",
                Bottle_100g: "100g",
            }
        }
    },
]

const products = {
    cookingOil : cookingOil,
    banaspati : banaspati,
    margarine : margarine,
}

export default products;
