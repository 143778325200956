import { Card, Heading } from "../../Components/";
import { Products as api } from "../../Api";
import { Container } from "react-bootstrap";
import { formatString } from "../../Common";
import "./products.style.css";

const Products = () => {
    const cookingOil = [...api.cookingOil];
    cookingOil.pop();
    return(
        <div className="product">
            <div className="pageTitle">
                <h4>Products</h4>
            </div>

            <Container>
                <div className="wrepper mt-5">
                    <Heading title="Cooking Oil" />
                    <div className="cardsWrepper">
                        {cookingOil.map((oil, index) => (
                            <Card name={oil.name} image={oil.image} tagline={oil.tag} link={`/products/cookingOil/${formatString(oil.name)}`} />
                        ))}
                    </div>
                </div>
                <div className="wrepper">
                    <Heading title="Banaspati" />
                    <div className="cardsWrepper">
                        {api.banaspati.map((ghee, index) => (
                            <Card name={ghee.name} image={ghee.image} tagline={ghee.tag} link={`/products/banaspati/${formatString(ghee.name)}`} />
                        ))}
                    </div>
                </div>
                <div className="wrepper">
                    <Heading title="Margarine" />
                    <div className="cardsWrepper">
                        {api.margarine.map((margarine, index) => (
                            <Card name={margarine.name} image={margarine.image} tagline={margarine.tag} link={`/products/margarine/${formatString(margarine.name)}`} />
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Products;