import "./home.css";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import {images} from "../../constants";
import { Heading, Card, Testimonial } from "../../Components";
import {Products} from "../../Api";
import {formatString} from "../../Common";


const chunkArray = (array, size) => {
    return array.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / size);
        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }
        result[chunkIndex].push(item);
        return result;
    }, []);
};

const Home = () => {
    const itemsPerSlide = 2;
    const slides = chunkArray(Products.cookingOil, itemsPerSlide);
console.log(slides);
    return(
        <>
        <div className="banner">
            <Container>
                <Row>
                    <Col lg="6" className="align-content-center">
                        <h1>Pakistan Oil Mills</h1>
                        <span>Pvt. Ltd</span>
                        <p>Pakistan Oil Mills Products Are Prepared Strictly In Accordance With The Pakistan Standard Quality Control Authority.<br/><br/>Pakistan Oil Mills Has Vast Experience In Edible Oil Business , It Has Established In 1990 With Well Equipped Composite Vegetable Oil And Banaspati Manufacturing Unit And Is Gaining Good Market Share.</p>
                        <div className="button">
                            <a href="/about">
                                <button>Learn More</button>
                            </a>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="image" >
                            {/* <img src={images.frame2} className="frame"/> */}
                            <Carousel fade controls={false} indicators={false} pause={false} interval={4000}>
                                <Carousel.Item>
                                <img src={images.banner1} />
                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.banner2} />
                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.banner2} />
                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.ad5} />
                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.ad6} />
                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.ad7} />
                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.ad8} />
                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.ad9} />
                                    
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="products">
            <Container>
                <Heading title="Products" />
                
                <div className="productsWrepper " >
                    <Row>
                        <Col lg={6} md={12}>
                            <Carousel className="cardGroup " controls={false} indicators={false} interval={5000} pause={false}>
                                {slides.map((group, index) => (
                                    <Carousel.Item key={index}>
                                        <div className="d-flex justify-content-center">
                                            {group.map((oil, idx) => (
                                                <Card key={idx} name={oil.name} image={oil.image} tagline={oil.tag} link={`/products/cookingOil/${formatString(oil.name)}`} />
                                            ))}
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                        <Col lg={3} md={6}>
                            <Carousel controls={false} indicators={false} interval={5000} pause={false}>
                                {Products.banaspati.map((ghee, index) => (
                                    <Carousel.Item key={index}>
                                        <Card name={ghee.name} image={ghee.image} tagline={ghee.tag} link={`/products/banaspati/${formatString(ghee.name)}`} />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                        <Col lg={3} md={6}>
                            <Carousel controls={false} indicators={false} interval={5000} pause={false}>
                                {Products.margarine.map((margarine, index) => (
                                    <Carousel.Item key={index}>
                                        <Card name={margarine.name} image={margarine.image} tagline={margarine.ctg} link={`/products/margarine/${formatString(margarine.name)}`} />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

        <Testimonial breakpoints={{1500: {slidesPerView: 3,}}} slides={2} space={20} title={"Testimonial"} />

        <div className="certificates">
            <Container>
                <div className="d-flex certificateWrepper">
                    <img src={images.certificate1} />
                    <img src={images.certificate2} />
                    <img src={images.certificate3} />
                    <img src={images.certificate4} />
                    <img src={images.certificate5} />
                    <img src={images.certificate6} />
                </div>
            </Container>
        </div>
        </>
    )
}

export default Home;